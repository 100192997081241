<template>
  <div class="d-flex align-items-center user-select-none">
    <span
      class="mr-2 text"
      :class="{
        _active: adapter.sortCol === keyCol,
        _selectable: keyColArrow,
        _white: enableWhite
      }"
      @click="labelClickEvent"
    >
      {{label}}
    </span>
    <span
      class="rounded-circle bg-white popover-wrap d-inline-flex justify-content-center mr-2"
      v-if="hintContent"
      v-c-popover="{
        header: label,
        content: hintContent,
        placement: 'up',
        html: true,
        appendToBody: true
      }"
    >
      ?
    </span>
    <CIcon
      class="switcher"
      :class="{
        _active: sorting(keyCol) || sorting(keyColArrow),
        _reverse: sorting(keyCol) === 'asd' || sorting(keyColArrow) === 'asd',
        _white_fill: enableWhite
      }"
      name="arrow-strong"
      @click.native="arrowClickEvent"
    />
  </div>
</template>

<script>
import screenerDataAdapter from "@/assets/js/screenerDataAdapter"
export default {
  name: "SelectedCellHeader",
  props: {
    enableWhite: {
      type: Boolean,
      default: false
    },
    keyCol: {
      type: String,
      required: true
    },
    keyColArrow: {
      type: String,
      default: ''
    },
    label: String,
    hintContent: String
  },
  data () {
    return {
      adapter: screenerDataAdapter
    }
  },
  methods: {
    labelClickEvent () {
      if (this.keyColArrow) {
        this.changeSort(this.keyCol)
      }
    },
    arrowClickEvent () {
      const key = this.keyColArrow ? this.keyColArrow : this.keyCol
      this.changeSort(key)
    },
    changeSort (key) {
      if (this.adapter.sortCol === key) {
        if (!this.sorting(key)) {
          this.adapter.sortType = 'desc'
        } else if (this.sorting(key) === 'desc') {
          this.adapter.sortType = 'asd'
        } else {
          this.adapter.sortCol = null
          this.adapter.sortType = null
        }
      } else {
        this.adapter.sortCol = key
        this.adapter.sortType = 'desc'
      }
    },
    sorting (key) {
      if (
        this.adapter.sortCol === key &&
        ['asd', 'desc'].indexOf(this.adapter.sortType) !== -1
      ) {
        return this.adapter.sortType
      } else {
        return null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
//@import "@/assets/scss/style";

.switcher {
  cursor: pointer;
  z-index: 1;
  opacity: 0.15;
  transition: all 250ms ease-out;
  &:hover {
    opacity: 0.3;
  }
  &._active {
    opacity: 1;
  }
  &._reverse {
    transform: rotate(180deg);
  }
}
.text {
  z-index: 1;
  opacity: 0.7;
  &._selectable {
    cursor: pointer;
    &:hover {
      opacity: 0.9;
    }
  }
  &._active {
    opacity: 1 !important;
  }
}
.popover-wrap {
  cursor: pointer;
  color: var(--primary);
  display: inline-flex;
  width: 1rem;
  height: 1rem;
  font-size: 0.75rem;
  align-items: center;
  flex-shrink: 0;
}

::v-deep ._white {
  color: #fff;
  opacity: 0.7;

  &:hover {
    opacity: 0.85;
  }

  & ._active {
    opacity: 1;
  }
}

::v-deep ._white_fill {
  fill: #fff;
  opacity: 0.7;

  &:hover {
    opacity: 0.85;
  }

  & ._active {
    opacity: 1;
  }
}

</style>

