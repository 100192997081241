<template>
  <CSelect
    class="select-custom mb-0"
    :label="isMobile ? $t('screener.tablesView.sortMobile') : $t('screener.tablesView.sort')"
    :horizontal="{
      label: 'fit-content',
      input: 'fit-content'
    }"
    :value="value"
    :options="options"
    placeholder="Выберите"
    size="sm"
    v-on="$listeners"
  />
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "RsiSortSelector",
  props: {
    value: String,
    options: Array
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters({
      isMobile: 'mediaQuery/isMobile',
      isTablet: 'mediaQuery/isTablet',
      isLaptop: 'mediaQuery/isLaptop',
      isDesktop: 'mediaQuery/isDesktop',
    }),
  }
}
</script>

<style lang="scss" scoped>
.select-custom {
  /deep/ .fit-content{
    width: fit-content !important;
  }
  /deep/ .form-control {
    background-color: var(--dark);
  }
  /deep/ label {
    white-space: nowrap;

    @media (max-width: 400px) {
      display: none;
    }
  }

  /deep/ .input-group-sm {
    @media (max-width: 400px) {
      flex: 0 0 95%;
      max-width: 95%;
    }
  }
}
</style>
