<template>
  <div class="w-100 tables-view position-relative" :class="{ '_no-select': selectShiftActive }">
    <div
      class="table-wrap watch-selector-parent-scroll pr-2"
      id="emtyListScrollWrapper"
      v-move-to-top="vMoveToTopOptions"
      :class="{empty: !rows.length}"
    >
      <q-virtual-scroll
        type="table"
        :virtual-scroll-item-size="virtualScrollItemSize"
        :virtual-scroll-sticky-size-start="virtualScrollStickySizeStart"
        :virtual-scroll-slice-size="virtualScrollSliceSize"
        :items="rows"
        scroll-target=".table-wrap"
        class="table"
      >
        <template v-slot:before>
          <thead class="thead-sticky text-left">
          <tr class="header-top">
            <th class="switch-custom-wrap" :colspan="isDesktop ? 3 : 2">
              <div class="d-flex align-items-center">
                <div class="d-flex align-items-center">
                  <label class="mb-0 col-form-label-sm" @click="isRsi = false">Volume</label>
                  <CSwitch
                    color="primary"
                    shape="pill"
                    :checked.sync="isRsi"
                    class="mx-3 switch-custom"
                    id="switch"
                  />
                  <label class="mb-0 col-form-label-sm" @click="isRsi = true">RSI</label>
                </div>

                <portal
                  :disabled="isDesktop"
                  to="screener-header"
                >
                  <CButtonGroup :class="['d-flex btn-group-sm', !isDesktop ? 'mr-2' : '', 'sort-by-hint-wrap']">
                    <CButton
                      v-for="item in filterTabList"
                      v-tooltip="{
                        content: $t(`screener.tablesView.sortByHint${item.label}`),
                      }"
                      :key="item.id"
                      color="dark"
                      :class="['cbutton']"
                      :variant="adapter.sortTypeBase === item.value ? '' : 'outline'"
                      @click="setFilterTab(item)"
                    >
                      {{ item.label }}
                    </CButton>
                  </CButtonGroup>
                </portal>
              </div>
            </th>

            <th
              id="emtyListScrollToElelemt"
              class="px-0"
              :class="{'position-relative': !rows.length}"
              :colspan="(isDesktop ? filteredTfList.length : 1) + filteredDataList.length - 2"
            >
              <template v-if="isShowMA">
                <color-filter-header/>
                <transition name="fade" appear>
                  <CIcon
                    v-if="!rows.length"
                    class="emptyListPlaceholder__pointerIcon"
                    name="pointer"/>
                </transition>
              </template>
            </th>
            <th class="table-description text-right" :colspan="isDesktop ? 1 : 2">
              {{ adapter.activeCol ? `${adapter.activeCol.toUpperCase()} ` + $t('screener.stats') : '' }}
            </th>
          </tr>
          <tr class="header-bottom">
            <th class="base-cell _first">
              <watch-selector
                v-if="adapter.filterMA200Trend || activeRows.length > 1"
                :showed-rows="activeRows.length > 1 ? activeRows : rows"
                :popover-options="{
                  popoverClass: 'v-tooltip popover--header',
                }"
                :is-futures="isActiveFutures"
                is-table-header
                @getData="getData"
              />

              <div
                class="pl-2 d-flex align-items-center"
              >
                <button
                  v-if="settings.selectedPairs.length && activeRows.length > 1"
                  type="button"
                  class="vs__deselect"
                  :title="`${$t('general.deselect')}`"
                  :aria-label="`${$t('general.deselect')}`"
                  @click.stop="removeSelectedPairs(activeRows)"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10">
                    <path
                      d="M6.895455 5l2.842897-2.842898c.348864-.348863.348864-.914488 0-1.263636L9.106534.261648c-.348864-.348864-.914489-.348864-1.263636 0L5 3.104545 2.157102.261648c-.348863-.348864-.914488-.348864-1.263636 0L.261648.893466c-.348864.348864-.348864.914489 0 1.263636L3.104545 5 .261648 7.842898c-.348864.348863-.348864.914488 0 1.263636l.631818.631818c.348864.348864.914773.348864 1.263636 0L5 6.895455l2.842898 2.842897c.348863.348864.914772.348864 1.263636 0l.631818-.631818c.348864-.348864.348864-.914489 0-1.263636L6.895455 5z"/>
                  </svg>
                </button>

                <table-cell-header
                  key-col="coin.label"
                  label="Coin"
                  :allow-select="false"
                />
              </div>
            </th>
            <template v-if="isDesktop">
              <th
                v-for="(tf, i) in filteredTfList"
                :key="i"
                :class="{ _last: i === filteredTfList.length - 1 }"
                class="base-cell _cursor"
                @click="adapter.activeCol = tf"
              >
                <table-cell-header
                  :key-col="tf"
                  :label="tf.toUpperCase()"
                />
              </th>
            </template>
            <th v-else class="base-cell _last pl-0">
              <table-cell-header
                :key-col="adapter.activeCol"
                :show-active="false"
              >
                <CSelect
                  :value.sync="adapter.activeCol"
                  :options="tfSelectOptions"
                  class="select-active-col mb-0"
                  placeholder="Выберите"
                  size="sm"
                />
              </table-cell-header>
            </th>
            <th class="empty"></th>

            <template
              v-for="(item, i) in filteredDataList"
            >
              <th
                :key="dataTableHeaderBlocks[item].id"
                v-if="!dataTableHeaderBlocks[item].hideTh"
                :class="[
                  dataTableHeaderBlocks[item].getThClass(),
                  {
                    _first: !i,
                    _last: i === filteredDataList.length - 1,
                    'pr-0': i === filteredDataList.length - 1,
                  }
                ]"
              >
                <selected-cell-header
                  :enableWhite="dataTableHeaderBlocks[item]?.enableWhite"
                  :key-col="dataTableHeaderBlocks[item]?.keyCol()"
                  :key-col-arrow="dataTableHeaderBlocks[item]?.keyColArrow()"
                  :label="dataTableHeaderBlocks[item]?.getLabel()"
                  :hint-content="dataTableHeaderBlocks[item]?.hintContent()"
                />
              </th>
            </template>

          </tr>
          </thead>
        </template>

        <template v-slot="{ item: row, index: rowIndex }">
          <tr
            :key="rowIndex"
            :class="row._classes"
            @click="selectRow(row, $event)"
          >
            <td class="position-relative align-middle label-custom-wrap">
              <watch-selector
                :ticker="row.coin.label"
                :is-futures="isActiveFutures"
              />

              <div
                v-if="row.coin.label"
                class="pl-2 d-flex align-items-center"
              >
                <button
                  v-if="settings.selectedPairs.length"
                  type="button"
                  class="vs__deselect"
                  :title="`${$t('general.deselect')} ${row.coin.label}`"
                  :aria-label="`${$t('general.deselect')} ${row.coin.label}`"
                  @click.stop="removeSelectedPairs(row)"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10">
                    <path
                      d="M6.895455 5l2.842897-2.842898c.348864-.348863.348864-.914488 0-1.263636L9.106534.261648c-.348864-.348864-.914489-.348864-1.263636 0L5 3.104545 2.157102.261648c-.348863-.348864-.914488-.348864-1.263636 0L.261648.893466c-.348864.348864-.348864.914489 0 1.263636L3.104545 5 .261648 7.842898c-.348864.348863-.348864.914488 0 1.263636l.631818.631818c.348864.348864.914773.348864 1.263636 0L5 6.895455l2.842898 2.842897c.348863.348864.914772.348864 1.263636 0l.631818-.631818c.348864-.348864.348864-.914489 0-1.263636L6.895455 5z"/>
                  </svg>
                </button>

                <div v-if="row.coin.icon" class="mr-2">
                  <img
                    :src="row.coin.icon"
                    style="width: 1.37rem; flex-shrink: 0"
                  >
                </div>
                <p class="mb-0 font-weight-bold overflow-hidden label-custom">
                  {{ row.coin.label }}
                </p>
              </div>
            </td>
            <template
              v-for="(tf, tfIndex) in filteredTfList"
            >
              <td
                v-if="adapter.activeCol === tf || isDesktop"
                :key="tfIndex"
                :style="{background: isRsi ? row[tf].colorRsi : ''}"
                class="badge-wrap"
              >
                <div>
                  {{ isRsi ? row[tf].rsi : row[tf].getVolumeStr }}
                  <button
                    v-tooltip="{
                      content: $t('screener.openGraph'),
                      container: '.c-app',
                    }"
                    type="button"
                    class="cil-bar-chart-btn"
                    @click.stop="graphClickHandler(row.coin.label, tfIndex, user.getAccess(1), row.coin)"
                >
                    <CIcon
                      width="0.75rem"
                      class="cursor-pointer"
                      name="cil-bar-chart"
                    />
                  </button>
                </div>
                <span
                  class="span-badge"
                  :style="{background: isRsi ? row[tf].colorRsiPct : row[tf].getColorVolumePct}"
                >
                  {{ isRsi ? row[tf].rsiPctStr : row[tf].getVolumePctStr }}
                </span>
              </td>
            </template>

            <td class="empty"></td>
            <template
              v-for="item in filteredDataList"
            >
              <td
                :key="dataTableBlocks[item].id"
                v-if="!dataTableBlocks[item].hideTd"
                :style="dataTableBlocks[item].getTdStyle(row)"
                v-tooltip="dataTableBlocks[item].getTooltipContent()"
                :class="dataTableBlocks[item].getTdClass(row)"
                @click="dataTableBlocks[item].tdClickHandler()"
              >
                <div>{{ dataTableBlocks[item].getData1Text(row) }}</div>
                <span class="span-badge" :class="dataTableBlocks[item].getBadgeClass(row)"
                      :style="dataTableBlocks[item].getBadgeStyle(row)">
                    {{ dataTableBlocks[item].getBadgeText(row) }}
                  </span>
                <div>{{ dataTableBlocks[item].getData2Text(row) }}</div>
              </td>
            </template>

          </tr>
        </template>
      </q-virtual-scroll>
    </div>
    <transition name="fade" appear>
      <div
        v-if="!rows.length"
        class="emptyListPlaceholder">
        <div class="emptyListPlaceholder__wrapper">
          <CIcon
            class="emptyListPlaceholder__emptyListIcon"
            name="emptyList"/>
          <span class="emptyListPlaceholder__title">
            {{ $t('screener.tablesView.emptyListTitle') }}
          </span>
        </div>
      </div>
    </transition>

    <CandlesGraphModal
      ref="candles"
      :rows="rows"
      :settings="settings"
      :client-id="clientId"
      :hide-notification-modal="isActiveFutures"
      :tables-intervals-for-use="filteredTfList"
      :is-futures="isActiveFutures"
      @getData="getData"
      @close="$emit('closeGraph')"
    />
  </div>
</template>

<script>
import TableCellHeader from "@/components/screener/tables/TableCellHeader"
import screenerDataAdapter, {sortTypeBaseList} from "@/assets/js/screenerDataAdapter"
import axios from "axios";
import ColorFilterHeader from "@/components/screener/tables/ColorFilterHeader"
import SelectedCellHeader from "@/components/screener/tables/SelectedCelHeader"
import RsiSortSelector from "@/components/screener/tables/RsiSortSelector"
import WatchSelector from "@/components/screener/WatchSelector"
import toastr from "toastr";
import CandlesGraphModal from "@/components/screener/tables/CandlesGraphModal";
import {mapGetters, mapActions} from "vuex";
import {createWatcherExhangeAndScreen} from "@/mixins/watch-exhange-and-screen";
import {
  CLIENT_ID_CANDLES_BINANCE_FUTURES,
  CLIENT_ID_DEFAULT,
  SCREENER_COLUMNS_FUTURES_SETTINGS_KEY,
  SCREENER_COLUMNS_SETTINGS_KEY,
  SCREENER_PAGE_FUTURES,
  TIME_FRAME_FUTURES_SETTINGS_KEY,
  TIME_FRAME_SETTINGS_KEY
} from "@/shared/constants";
import {showMa} from "@/mixins/show-ma";


const ACTIVE_ROW_CLASS = 'active-row'
const ROW_HEIGHT_MOBILE = 63
const ROW_HEIGHT_TABLET = 75
const ROW_HEIGHT_DESKTOP = 88
const ADD_SLICE_COUNT = 5

export default {
  name: "TablesView",
  mixins: [
    showMa,
    createWatcherExhangeAndScreen(({getData, resetMAIfHidden}) => {
      resetMAIfHidden()
      getData()
    }),
  ],
  components: {
    CandlesGraphModal,
    WatchSelector,
    TableCellHeader,
    ColorFilterHeader,
    SelectedCellHeader,
    RsiSortSelector,
  },
  props: {
    settings: Object,
    isPaused: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sortTypeBaseList,
      adapter: screenerDataAdapter,
      loadingData: false,
      timer: null,
      timerIntervalDefault: 10000,
      // cancelEvent: null,
      rsiSortType: sortTypeBaseList[0],
      activeRows: [],
      // isSlidingVolume: this.$store.state.userSettings.settings?.tablesView?.isSlidingVolume,
      showCandlesGraph: this.$store.getters['user/getPermissions']('view_screener_graphs'),
      showDeltaColumns: this.$store.getters['user/getPermissions']('use_custom_ma'),
      selectShiftActive: false,
      filterTabList: [
        {
          id: 1,
          label: 'ABS',
          value: sortTypeBaseList[0],
        },
        {
          id: 2,
          label: '%',
          value: sortTypeBaseList[1],
        },

      ],
      filterTabValue: null,
      dataTableBlocks: {
        1: {
          id: 'Price',
          getTdClass: () => '',
          getTdStyle: () => ({}),
          getData1Text: row => row[this.adapter.activeCol].price,
          getBadgeText: row => row[this.adapter.activeCol].pricePctStr,
          getBadgeClass: () => '',
          getBadgeStyle: (row) => ({background: row[this.adapter.activeCol].colorPricePct}),
          getData2Text: () => '',
          getTooltipContent: () => ({}),
          tdClickHandler: () => null
        },
        2: {
          id: 'CD Week',
          hideTd: this.showDeltaColumns,
          getTdClass: () => this.user.getAccess(1) ? '' : 'blur',
          getTdStyle: (row) => ({background: row[this.adapter.activeCol].deltaWColor}),
          getData1Text: row => this.user.getAccess(1) ? row[this.adapter.activeCol].tf1w_quote_volume_delta_str : '000',
          getBadgeText: () => 'Previous',
          getBadgeClass: () => 'p-0',
          getBadgeStyle: () => ({}),
          getData2Text: row => this.user.getAccess(1) ? row[this.adapter.activeCol].prev_tf1w_quote_volume_delta_str : '000',
          getTooltipContent: () => this.user.getAccess(1) ? false : ({
            content: this.$t('screener.accessTooltip'),
            container: '.c-app',
          }),
          tdClickHandler: () => !this.user.getAccess(1) ? this.graphClickHandler(null, null, false) : null
        },
        3: {
          id: 'Delta',
          hideTd: this.showDeltaColumns,
          getTdClass: () => this.user.getAccess(1) ? '' : 'blur',
          getTdStyle: (row) => ({background: row[this.adapter.activeCol].deltaColor}),
          getData1Text: row => this.user.getAccess(1) ? row[this.adapter.activeCol].tf_quote_volume_delta_str : '000',
          getBadgeText: () => 'Previous',
          getBadgeClass: () => 'p-0',
          getBadgeStyle: () => ({}),
          getData2Text: row => this.user.getAccess(1) ? row[this.adapter.activeCol].prev_tf_quote_volume_delta_str : '000',
          getTooltipContent: () => this.user.getAccess(1) ? false : ({
            content: this.$t('screener.accessTooltip'),
            container: '.c-app',
          }),
          tdClickHandler: () => !this.user.getAccess(1) ? this.graphClickHandler(null, null, false) : null
        },
        4: {
          id: 'Volatility',
          getTdClass: () => 'align-middle pr-0',
          getTdStyle: () => ({}),
          getData1Text: row => row[this.adapter.activeCol].volatility,
          getBadgeText: () => '',
          getBadgeClass: () => '',
          getBadgeStyle: () => ({}),
          getData2Text: () => '',
          getTooltipContent: () => false,
          tdClickHandler: () => null
        },
        5: {
          id: 'MA50',
          getTdClass: (row) => row[this.adapter.activeCol].ma50class,
          getTdStyle: () => ({}),
          getData1Text: row => this.showMaInAbsolute ? row[this.adapter.activeCol].ma50 : '',
          getBadgeText: (row) => row[this.adapter.activeCol][`${this.correctMa.ma50}_str`],
          getBadgeClass: () => '',
          getBadgeStyle: (row) => ({
            display: this.showMaInPercent ? 'initial' : 'none',
            background: this.maPinColor(row[this.adapter.activeCol][this.correctMa.ma50])
          }),
          getData2Text: () => '',
          getTooltipContent: () => false,
          tdClickHandler: () => null
        },
        6: {
          id: 'MA200',
          getTdClass: (row) => row[this.adapter.activeCol].ma200class,
          getTdStyle: () => ({}),
          getData1Text: row => this.showMaInAbsolute ? row[this.adapter.activeCol].ma200 : '',
          getBadgeText: (row) => row[this.adapter.activeCol][`${this.correctMa.ma200}_str`],
          getBadgeClass: () => '',
          getBadgeStyle: (row) => ({
            display: this.showMaInPercent ? 'initial' : 'none',
            background: this.maPinColor(row[this.adapter.activeCol][this.correctMa.ma200])
          }),
          getData2Text: () => '',
          getTooltipContent: () => false,
          tdClickHandler: () => null
        },
        7: {
          id: 'Volume',
          getTdClass: () => '',
          getTdStyle: (row) => ({background: this.isRsi ? '' : row[this.adapter.activeCol].colorRsi}),
          getData1Text: row => this.isRsi ? row[this.adapter.activeCol].getVolumeStr : row[this.adapter.activeCol].rsi,
          getBadgeText: (row) => this.isRsi ? row[this.adapter.activeCol].getVolumePctStr : row[this.adapter.activeCol].rsiPctStr,
          getBadgeClass: () => '',
          getBadgeStyle: (row) => ({background: this.isRsi ? row[this.adapter.activeCol].getColorVolumePct : row[this.adapter.activeCol].colorRsiPct}),
          getData2Text: () => '',
          getTooltipContent: () => false,
          tdClickHandler: () => null
        },
      },
      dataTableHeaderBlocks: {
        1: {
          id: 'price1',
          getThClass: () => 'selected-cell _minwidth',
          enableWhite: true,
          keyCol: () => "price",
          keyColArrow: () => "pricePct",
          getLabel: () => "Price",
          hintContent: () => ''
        },
        2: {
          id: 'CD Week2',
          hideTh: this.showDeltaColumns,
          getThClass: () => 'selected-cell _minwidth',
          enableWhite: true,
          keyCol: () => "tf1w_quote_volume_delta",
          keyColArrow: () => null,
          getLabel: () => "CD Week",
          hintContent: () => ''
        },
        3: {
          id: 'Delta3',
          hideTh: this.showDeltaColumns,
          getThClass: () => 'selected-cell _minwidth',
          enableWhite: true,
          keyCol: () => "tf_quote_volume_delta",
          keyColArrow: () => null,
          getLabel: () => "Delta",
          hintContent: () => ''
        },
        4: {
          id: 'Volat4',
          getThClass: () => 'selected-cell',
          enableWhite: true,
          keyCol: () => "volatility",
          keyColArrow: () => null,
          getLabel: () => "Volat.",
          hintContent: () => this.$t('screener.tablesView.volatDesc')
        },
        5: {
          id: 'ma505',
          getThClass: () => 'selected-cell _ma',
          enableWhite: true,
          keyColArrow: () => this.correctMa?.ma50,
          keyCol: () => "ma50",
          getLabel: () => this.maLowLabel,
          hintContent: () => ''
        },
        6: {
          id: 'ma2006',
          getThClass: () => 'selected-cell _ma',
          enableWhite: true,
          keyColArrow: () => this.correctMa?.ma200,
          keyCol: () => "ma200",
          getLabel: () => this.maHighLabel,
          hintContent: () => ''
        },
        7: {
          id: 'Volume7',
          getThClass: () => 'selected-cell',
          enableWhite: true,
          keyColArrow: () => this.lastColumnHeader.keyColArrow,
          keyCol: () => this.lastColumnHeader.keyCol,
          getLabel: () => this.lastColumnHeader.label,
          hintContent: () => this.lastColumnHeader.hintContent
        },
      },
      defaultDataSettings: [1, 2, 3, 4, 5, 6, 7],
    }
  },
  created() {
    this.getData()
    window.addEventListener('keydown', this.keydown, false)
    window.addEventListener('keyup', this.keyup, false)
  },
  mounted() {
    if (!this.filteredTfList.includes(this.adapter.activeCol)) {
      this.adapter.activeCol = this.filteredTfList[0]
    }

    this.resetMAIfHidden()
  },
  destroyed() {
    if (this.timer) {
      clearTimeout(this.timer)
    }
    // if (this.cancelEvent) {
    //   this.cancelEvent()
    // }
    if (this.cancelToken) {
      this.cancelToken.cancel()
    }
    window.removeEventListener('keydown', this.keydown, false)
    window.removeEventListener('keyup', this.keyup, false)
  },
  computed: {
    filteredTfList() {
      let settings = [...this.$store.state.settings?.screener?.[this.timeFrameSettingsKey] || []]
      if (settings.length) {
        return settings.map(setting => setting.value)
      }
      return this.settings.tablesIntervals
    },
    filteredDataList() {
      let settings = [...this.$store.state.settings?.screener?.[this.screenerColumnsSettingsKey] || []]
      if (settings.length) {
        return settings = settings.map(setting => setting.id)
      }
      return this.defaultDataSettings
    },
    user() {
      return this.$store.state.user.userData
    },
    ...mapGetters({
      cancelToken: 'cancelToken',
      tablesData: 'tablesData',
      isBrowserTabActive: 'isBrowserTabActive',
      isMobile: 'mediaQuery/isMobile',
      isTablet: 'mediaQuery/isTablet',
      isLaptop: 'mediaQuery/isLaptop',
      isDesktop: 'mediaQuery/isDesktop',
      isGuest: 'user/isGuest'
    }),
    tfSelectOptions() {
      return this.filteredTfList.map(item => ({
        label: item.toUpperCase(),
        value: item
      }))
    },
    correctMa() {
      return ({
        ma200: this.$store.state.settings?.ma_price_dependence?.value === 'price_ma' ?
          'price_ma200_difference_perc' : 'ma200_price_difference_perc',
        ma50: this.$store.state.settings?.ma_price_dependence?.value === 'price_ma' ?
          'price_ma50_difference_perc' : 'ma50_price_difference_perc'
      })
    },
    showMaInPercent() {
      // for users who did not save settings yet
      if (!this.$store.state.settings?.ma_price_dependence?.display_value ||
        !this.$store.state.settings?.ma_price_dependence?.display_value.length) {
        return true
      }
      return this.$store.state.settings?.ma_price_dependence?.display_value.includes('percent')
    },
    showMaInAbsolute() {
      // for users who did not save settings yet
      if (!this.$store.state.settings?.ma_price_dependence?.display_value ||
        !this.$store.state.settings?.ma_price_dependence?.display_value.length) {
        return true
      }
      return this.$store.state.settings?.ma_price_dependence?.display_value.includes('absolute')
    },
    vMoveToTopOptions() {
      if (this.isMobile || this.isTablet) {
        return {
          position: {bottom: 80, right: 20}
        }
      } else return {
        position: {bottom: 80, right: -35}
      }
    },
    isRsi: {
      get() {
        return this.adapter.isRsi
      },
      set(val) {
        this.adapter.isRsi = val
      }
    },
    isSlidingVolume: {
      get() {
        return this.adapter.isSlidingVolume
      },
      set(val) {
        this.adapter.isSlidingVolume = val
      }
    },
    rows() {
      return this.adapter.data
    },
    lastColumnHeader() {
      const viewVolume = this.isRsi && !this.isSlidingVolume
      const viewSlidingVolume = this.isRsi && this.isSlidingVolume
      const viewRsi = !this.isRsi

      if (viewVolume) {
        return {
          keyCol: 'volume',
          keyColArrow: 'volumePct',
          label: 'Volume',
          hintContent: this.$t('screener.tablesView.volumeDesc')
        }
      } else if (viewSlidingVolume) {
        return {
          keyCol: 'slidingVolume',
          keyColArrow: 'slidingVolumePct',
          label: 'Volume',
          hintContent: this.$t('screener.tablesView.volumeDesc')
        }
      } else if (viewRsi) {
        return {
          keyCol: 'rsi',
          keyColArrow: 'rsiPct',
          label: 'RSI',
          hintContent: ''
        }
      } else {
        return {
          keyCol: '',
          keyColArrow: '',
          label: '',
          hintContent: ''
        }
      }
    },
    maHighLabel() {
      const highValue = this.$store.state.settings?.ma?.high
      if (!highValue) return 'MA200'

      return `MA${highValue}`
    },
    maLowLabel() {
      const lowValue = this.$store.state.settings?.ma?.low
      if (!lowValue) return 'MA50'

      return `MA${lowValue}`
    },
    virtualScrollItemSize() {
      if (this.isMobile) {
        return ROW_HEIGHT_MOBILE
      } else if (this.isTablet) {
        return ROW_HEIGHT_TABLET
      } else return ROW_HEIGHT_DESKTOP
    },
    virtualScrollStickySizeStart() {
      if (this.isMobile || this.isTablet) {
        return 73.5
      } else if (this.isLaptop) {
        return 93.5
      } else return 90.5
    },
    virtualScrollSliceSize() {
      if (this.isMobile) {
        return Math.ceil(window.innerHeight / ROW_HEIGHT_MOBILE + ADD_SLICE_COUNT)
      } else if (this.isTablet) {
        return Math.ceil(window.innerHeight / ROW_HEIGHT_TABLET + ADD_SLICE_COUNT)
      } else return Math.ceil(window.innerHeight / ROW_HEIGHT_DESKTOP + ADD_SLICE_COUNT)
    },
    clientId() {
      let clientId = CLIENT_ID_DEFAULT

      if (this.isActiveFutures) {
        clientId = CLIENT_ID_CANDLES_BINANCE_FUTURES
      }

      return clientId
    },
    isActiveFutures() {
      return this.currentScreenAndExchange.screen === SCREENER_PAGE_FUTURES
    },
    timeFrameSettingsKey() {
      return this.isActiveFutures ? TIME_FRAME_FUTURES_SETTINGS_KEY : TIME_FRAME_SETTINGS_KEY
    },
    screenerColumnsSettingsKey() {
      return this.isActiveFutures ? SCREENER_COLUMNS_FUTURES_SETTINGS_KEY : SCREENER_COLUMNS_SETTINGS_KEY
    },
  },
  watch: {
    loadingData(val) {
      this.$emit('changeLoading', val)
    },
    isPaused(val) {
      if (val) {
        if (this.timer) {
          clearTimeout(this.timer)
        }
      } else {
        if (!this.loadingData) {
          this.getData()
        }
      }
    },
    isBrowserTabActive(val) {
      if (!val) {
        if (this.timer) {
          clearTimeout(this.timer)
        }
      } else {
        if (!this.loadingData && !this.isPaused) {
          this.getData()
        }
      }
    },
    tablesData: {
      deep: true,
      handler: function (val) {
        this.adapter.data = val
      },
    },
    rows(val) {
      if (!val.length) {
        const scrollableElement = document.getElementById('emtyListScrollWrapper');
        const targetElement = document.getElementById('emtyListScrollToElelemt');
        const targetElementPosition = targetElement.offsetLeft - scrollableElement.offsetLeft;
        scrollableElement.scrollLeft = targetElementPosition;
      }
      val.forEach((item) => {
        const row = this.activeRows.find(el => el.id === item.id)

        if (row) {
          item._classes = ACTIVE_ROW_CLASS
        } else {
          item._classes = ''
        }
      })
      this.activeRows.slice(0).forEach((el) => {
        const isRow = val.some(item => item.id === el.id)
        const index = this.activeRows.findIndex(item => item.id === el.id)

        if (!isRow && index > -1) {
          this.activeRows.splice(index, 1)
        }
      })
    },
  },
  methods: {
    ...mapActions('promo', ['toggleShowPromoModal']),
    setFilterTab({value}) {
      this.adapter.sortTypeBase = value
    },
    graphClickHandler(label, tfIndex, hasAccess) {
      if (hasAccess) {
        this.$emit('openGraph')
        this.$refs.candles.openCandlesGraph(label, tfIndex)
      } else {
        this.toggleShowPromoModal({
          show: true,
          content: {id: 'pro', sliderData: [{videoName: "tables", isVideo: true}]}
        })
      }
    },
    maPinColor(value) {
      return value >= 0 ? 'rgb(69, 161, 100)' : 'rgb(209, 103, 103)'
    },
    getData() {
      // if (this.cancelEvent) {
      //   this.cancelEvent()
      // }
      if (this.cancelToken) {
        this.cancelToken.cancel()
      }
      this.loadingData = true
      this.$store.commit('setCancelToken', axios.CancelToken.source())

      const getURL = this.isActiveFutures
        ? `/api/v3/${this.currentScreenAndExchange.exchange}_perp/screener/tables`
        : '/api/v2/screener/tables';

      axios.get(getURL, {
        // cancelToken: new axios.CancelToken((c) => {
        //   this.cancelEvent = c
        // })
        cancelToken: this.cancelToken.token
      })
        .then(res => {
          if (!this.adapter.activeCol) {
            this.adapter.activeCol = this.filteredTfList[0]
          }
          this.$store.commit('setTablesData', res?.data?.data)
          const arr = this.tablesData
          if (arr && Array.isArray(arr)) {
            this.adapter.data = arr
            if (this.activeRows.length) {
              this.activeRows.forEach((el) => {
                const row = this.rows.find(({id}) => id === el?.id)
                if (row) {
                  row._classes = ACTIVE_ROW_CLASS
                }
              })
            }
          }

          this.loadingData = false

          const delay = res?.data?.delay
          this.startTimer(delay)
        })
        .catch((e) => {
          if (e instanceof axios.Cancel) {
            toastr.remove()
          } else if (e?.response?.status !== 401) {
            this.loadingData = false
            this.startTimer()
          }
        })
      // this.adapter.data = 'random'
    },
    startTimer(delay) {
      if (this.isPaused || !this.isBrowserTabActive) return

      let localDelay = this.timerIntervalDefault
      if (this.isGuest) {
        // каждую шестую минуту
        localDelay = 360000 - Date.now() % 300000
        if (localDelay < this.timerIntervalDefault) {
          localDelay = this.timerIntervalDefault
        }
      } else if (delay && typeof delay === 'number') {
        localDelay = delay
      }

      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        if (this.loadingData) return
        this.getData()
      }, localDelay)
    },
    selectRow(row, e) {
      const idIndex = this.activeRows.findIndex((el) => el?.id === row.id)

      if (!e.shiftKey && !e.ctrlKey && !e.metaKey) {
        this.selectRowSingle(row, idIndex)
      } else if ((e.ctrlKey || e.metaKey) && !e.shiftKey) {
        this.selectRowSingleCtrl(row, idIndex)
      } else if (!e.ctrlKey && !e.metaKey && e.shiftKey) {
        this.selectRowMultipleShift(row)
      }
    },
    selectRowSingle(row, idIndex) {
      const currentRowIndex = this.rows.findIndex(({id}) => id === row.id)
      if (currentRowIndex > -1) {
        if (idIndex > -1 && this.activeRows.length < 2) {
          this.activeRows = []
          this.rows.forEach((el) => {
            el._classes = ''
          })
        } else {
          this.rows.forEach((el, index) => {
            if (index === currentRowIndex) {
              el._classes = ACTIVE_ROW_CLASS
            } else {
              el._classes = ''
            }
          })
          this.activeRows = [row]
        }
      }
    },
    selectRowSingleCtrl(row, idIndex) {
      if (idIndex > -1) {
        const prevIndex = this.rows.findIndex(({id}) => this.activeRows[idIndex]?.id === id)
        if (prevIndex > -1) {
          this.rows[prevIndex]._classes = ''
          this.activeRows.splice(idIndex, 1)
        }
      } else {
        const currentRowIndex = this.rows.findIndex(({id}) => id === row.id)
        if (currentRowIndex > -1) {
          this.rows[currentRowIndex]._classes = ACTIVE_ROW_CLASS
          this.activeRows.push(row)
        }
      }
    },
    selectRowMultipleShift(row) {
      const sel = window.getSelection()
      sel.removeAllRanges()
      let firstIndex = this.rows.findIndex(({id}) => this.activeRows.some(el => el?.id === id))
      const currentRowIndex = this.rows.findIndex(({id}) => id === row.id)

      firstIndex = firstIndex > -1 ? firstIndex : 0

      if (currentRowIndex > -1) {
        this.activeRows = []
        this.rows.forEach((el, index) => {
          if (firstIndex <= currentRowIndex) {
            if (index >= firstIndex && index <= currentRowIndex) {
              el._classes = ACTIVE_ROW_CLASS
              this.activeRows.push(el)
            } else {
              el._classes = ''
            }
          } else {
            if (index >= currentRowIndex && index <= firstIndex) {
              el._classes = ACTIVE_ROW_CLASS
              this.activeRows.push(el)
            } else {
              el._classes = ''
            }
          }
        })
      }
    },
    keydown(e) {
      if (e.shiftKey) {
        this.selectShiftActive = true
      }
    },
    keyup(e) {
      if (e.key === 'Shift') {
        this.selectShiftActive = false
      }
    },
    removeSelectedPairs(val) {
      console.log(Array.from(this.activeRows))
      if (Array.isArray(val)) {
        const valArr = Array.from(val)
        this.settings.removeSelectedPairs(valArr)
        valArr.forEach(item => {
          const index = this.activeRows.findIndex(el => el.id === item.id)

          if (index > -1) {
            this.activeRows.splice(index, 1)
          }
        })
      } else if (val?.id) {
        const index = this.activeRows.findIndex(el => {
          return el.id === val.id
        })
        console.log(index)
        this.settings.removeSelectedPairs(val)

        if (index > -1) {
          this.activeRows.splice(index, 1)
        }
      }
    },
    resetMAIfHidden() {
      if (!this.isShowMA) {
        this.adapter.filterMA200Trend = null
      }
    },
  }
}
</script>

<style lang="scss" scoped>
//@import "@/assets/scss/style";
.emptyListPlaceholder {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);

  &__wrapper {
    display: flex;
    flex-direction: column;
    width: 360px;
    align-items: center;
  }

  &__emptyListIcon,
  &__pointerIcon {
    fill: transparent;
  }

  &__pointerIcon {
    position: absolute;
    width: 107px !important;
    height: 165px !important;
    left: 0;
    right: 98px;
    margin: auto;
    top: 120px;
  }

  &__emptyListIcon {
    width: 99px !important;
    height: 98px !important;
    margin-bottom: 32px;
  }

  &__title {
    font-weight: 500;
    font-size: 24px;
    line-height: 130%;
    text-align: center;
  }
}

.table-wrap {
  overflow: auto;
  width: 100%;
  height: 100%;
}

.table {
  position: relative;

  /deep/ .q-table {
    width: 100%;
  }

  &-description {
    color: $gray-800;
    white-space: nowrap;
  }

  tr {
    background: var(--second-card-bg);

    &.active-row {
      background: var(--table-active-row);
    }
  }

  td {
    vertical-align: middle;
    white-space: nowrap;
    font-size: .75rem;
    padding: .25rem .5rem;

    @include media-breakpoint-up(md) {
      padding: .625rem .75rem;
    }

    @include media-breakpoint-up(lg) {
      font-size: .875rem;
      padding: .75rem;
    }

    &.label-custom-wrap {
      font-size: .625rem;
      padding-left: .75rem;

      @include media-breakpoint-up(md) {
        font-size: .75rem;
      }

      @include media-breakpoint-up(lg) {
        font-size: .875rem;
        padding: .75rem;
      }
    }
  }

  thead {
    tr.header-top {
      background: var(--base-card-bg);
    }

    tr th {
      font-size: .75rem;
      vertical-align: middle;
      padding: .25rem .5rem;

      @include media-breakpoint-up(md) {
        padding: .25rem .875rem;
      }

      @include media-breakpoint-up(lg) {
        font-size: .75rem;
        padding: .75rem;
      }
    }

    tr.header-top th {
      background: var(--base-card-bg);
      border: none;
      position: sticky;
      top: 0;
      z-index: 1;

      @include media-breakpoint-up(lg) {
        padding-top: 0 !important;
      }
    }

    tr.header-bottom th {
      border: none;
      position: sticky;
      top: 36px;
      z-index: 1;


      @include media-breakpoint-up(lg) {
        top: 40px
      }

      &.base-cell {
        background: var(--dark);

        &._cursor {
          cursor: pointer;
        }
      }

      &.selected-cell {
        background: var(--primary);

        &._minwidth {
          min-width: 6.4rem;
        }
      }

      &.base-cell,
      &.selected-cell {
        &._first {
          border-top-left-radius: .25rem;
        }

        &._last {
          border-top-right-radius: .25rem;
        }
      }

      &.selected-cell {
        &._first {
          min-width: 8rem;

          @include media-breakpoint-up(lg) {
            min-width: 7.5rem;
          }
        }

        &._ma {
          min-width: 5.625rem;

          @include media-breakpoint-up(md) {
            min-width: 6.25rem;
          }

          @include media-breakpoint-up(lg) {
            min-width: 6.875rem;
          }
        }
      }

      &.base-cell {
        &._first {
          min-width: 6.875rem;

          @include media-breakpoint-up(md) {
            min-width: 8.125rem;
          }

          @include media-breakpoint-up(lg) {
            min-width: 10.3rem;
          }
        }

        &._cursor {
          @include media-breakpoint-up(lg) {
            min-width: 6.33rem;
          }
        }
      }
    }
  }

  .empty {
    background: var(--base-card-bg);
    border: none;
  }

  /deep/ .q-virtual-scroll__padding {
    td {
      padding: 0;
    }
  }

  /deep/ .q-virtual-scroll__content {
    outline: none;
  }
}

.span-badge {
  // &.--ma{
  //   width: fit-content;
  //   display: block;
  //   margin: 0 auto;
  // }
  white-space: nowrap;
  border-radius: 10rem;
  padding: 0.25em 0.6em;
  font-size: 75%;
  line-height: 1;
  text-align: center;
  color: #ffffff;

  &_big {
    font-size: 100%;
  }
}

.trend {
  &_4 {
    background: rgba(255, 147, 7, 0.4) !important;
  }

  &_3 {
    background: rgba(248, 108, 107, 0.4) !important;
  }

  &_2 {
    background: rgba(255, 193, 7, 0.4) !important;
  }

  &_1 {
    background: rgba(77, 189, 116, 0.4) !important;
  }
}

.switch-custom {
  /deep/ .c-switch-slider {
    background-color: var(--primary) !important;
    border-color: var(--primary) !important;
  }
}

.select-active-col {
  min-width: 5.5rem;

  /deep/ {
    select {
      &,
      &:focus {
        background: var(--primary);
      }
    }
  }
}

.rsi-sort-selector {
  margin-right: .5rem;
  @include media-breakpoint-up(md) {
    // margin-right: -2.25rem;
    min-width: 13.5rem;
  }

  @include media-breakpoint-up(lg) {
    // margin-right: .5rem;
  }
}

.rsi-sort-selector-wrap,
.table .empty {
  padding: .15rem !important;

  @include media-breakpoint-up(md) {
    padding: .3rem !important;
  }

  @include media-breakpoint-up(lg) {
    padding: .6rem !important;
  }
}

.tables-view {
  /deep/ ._last {
    .active {
      &::before {
        border-top-right-radius: 0.25rem;
      }
    }
  }

  &._no-select {
    user-select: none !important;
  }

  //position: relative;
  //
  //// исправляет пробел между шапкой таблицы и родительским блоком при скроле в сафари
  //&:before {
  //  content: '';
  //  position: absolute;
  //  width: 100%;
  //  height: 2px;
  //  top: 0;
  //  background: var(--base-card-bg);
  //  pointer-events: none;
  //  user-select: none;
  //}
}

.vs__deselect {
  fill: var(--white);
  opacity: .2;
  flex-shrink: 0;
  margin-right: .5rem;
  outline: none !important;
  transition: opacity ease-in-out .2s;

  &:hover,
  &:focus-visible {
    opacity: .3;
  }
}

.cil-bar-chart-btn {
  padding: 0;
  border: none;
  background: none;
  color: inherit;
}

.empty {
  overflow-y: hidden;
  position: relative;
}

.blur {
  filter: blur(6px);
}

.cbutton {
  max-width: 45px;
  max-height: 29px;
}

.sort-by-hint-wrap {
  @include media-breakpoint-up(xl) {
    margin-left: 118px;
  }
}
</style>
