<template>
  <div
    class="d-flex align-items-center user-select-none"
    :class="{active: keyCol === adapter.activeCol && showActive}"
  >
    <span
      class="mr-2 text"
    >
      <slot>
        {{label}}
      </slot>
    </span>
    <CIcon
      class="switcher"
      :class="{
        _active: sorting,
        _reverse: sorting === 'asd'
      }"
      name="arrow-strong"
      @click.native.stop="changeSort"
    />
  </div>
</template>

<script>
import screenerDataAdapter from "@/assets/js/screenerDataAdapter"
export default {
  name: "TableCellHeader",
  props: {
    keyCol: String,
    label: String,
    showActive: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      adapter: screenerDataAdapter
    }
  },
  computed: {
    sorting () {
      if (
        this.adapter.sortCol === this.keyCol &&
        ['asd', 'desc'].indexOf(this.adapter.sortType) !== -1
      ) {
        return this.adapter.sortType
      } else {
        return null
      }
    }
  },
  methods: {
    changeSort () {
      if (this.adapter.sortCol === this.keyCol) {
        if (!this.sorting) {
          this.adapter.sortType = 'desc'
        } else if (this.sorting === 'desc') {
          this.adapter.sortType = 'asd'
        } else {
          this.adapter.sortType = null
        }
      } else {
        this.adapter.sortCol = this.keyCol
        this.adapter.sortType = 'desc'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
//@import "@/assets/scss/style";

.switcher {
  cursor: pointer;
  z-index: 1;
  opacity: 0.15;
  transition: all 250ms ease-out;
  &:hover {
    opacity: 0.3;
  }
  &._active {
    opacity: 1;
  }
  &._reverse {
    transform: rotate(180deg);
  }
}
.text {
  z-index: 1;
  opacity: 1;
  &._cursor {
    cursor: pointer;
  }
}
.active {
  position: relative;
  color: color-yiq(theme-color("primary"));
  &:before {
    z-index: 0;
    content: "";
    position: absolute;
    background: var(--primary);
    top: -12px;
    bottom: -12px;
    right: -12px;
    left: -12px;
  }
}

@media screen and (min-width:1888px) {
  .active::before {
    top: -12px;
    bottom: -12px;
  }
}
</style>
