<template>
  <div class="switcher-wrap">
    <div
      class="switcher switcher_3"
      :class="{_active: adapter.filterMA200Trend === 3}"
      @click="clickEvent(3)"
    >
      {{adapter.trendMA3}}
    </div>
    <div
      class="switcher switcher_2"
      :class="{_active: adapter.filterMA200Trend === 2}"
      @click="clickEvent(2)"
    >
      {{adapter.trendMA2}}
    </div>
    <div
      class="switcher switcher_1"
      :class="{_active: adapter.filterMA200Trend === 1}"
      @click="clickEvent(1)"
    >
      {{adapter.trendMA1}}
    </div>

    <span
      class="rounded-circle bg-dark popover-wrap d-inline-flex justify-content-center ml-2"
      v-c-popover="{
        header: 'MA',
        placement: 'up',
        html: true,
        appendToBody: true,
        content: `
          🔴 ${$t('screener.tablesView.colorFilterHint.danger')}<br/>
          🟡 ${$t('screener.tablesView.colorFilterHint.warning')}<br/>
          🟢 ${$t('screener.tablesView.colorFilterHint.success')}
        `
      }"
    >?</span>
  </div>
</template>

<script>
import screenerDataAdapter from "@/assets/js/screenerDataAdapter"
export default {
  name: "ColorFilterHeader",
  data () {
    return {
      adapter: screenerDataAdapter
    }
  },
  computed: {
  },
  methods: {
    clickEvent (type) {
      if (this.adapter.filterMA200Trend === type) {
        this.adapter.filterMA200Trend = null
      } else {
        this.adapter.filterMA200Trend = type
      }
    }
  }
}
</script>

<style lang="scss" scoped>
//@import "@/assets/scss/style";

.switcher-wrap {
  display: flex;
  align-items: center;
  justify-content: end;
}

.switcher {
  color: var(--white);
  position: relative;
  cursor: pointer;
  //width: 10px;
  flex-grow: 1;
  max-width: 55px;
  height: 10px;
  border-radius: 1rem;
  margin-right: 5px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  line-height: 1;
  font-weight: normal;
  &_3 {
    background-color: rgba(248, 108, 107, 0.5);
  }
  &_2 {
    background-color: rgba(255, 193, 7, 0.5);
  }
  &_1 {
    background-color: rgba(77, 189, 116, 0.5);
  }
}
._active {
  &.switcher_3 {
    background-color: rgba(248, 108, 107, 1);
  }
  &.switcher_2 {
    background-color: rgba(255, 193, 7, 1);
  }
  &.switcher_1 {
    background-color: rgba(77, 189, 116, 1);
  }
  &:before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 110%;
    height: 14px;
    content: "";
    border: 1px solid var(--white);
    border-radius: 1rem;
  }
}

.popover-wrap {
  cursor: pointer;
  display: inline-flex;
  width: 1rem;
  flex-shrink: 0;
  height: 1rem;
  font-size: 0.75rem;
}
</style>
