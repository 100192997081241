var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CSelect",
    _vm._g(
      {
        staticClass: "select-custom mb-0",
        attrs: {
          label: _vm.isMobile
            ? _vm.$t("screener.tablesView.sortMobile")
            : _vm.$t("screener.tablesView.sort"),
          horizontal: {
            label: "fit-content",
            input: "fit-content",
          },
          value: _vm.value,
          options: _vm.options,
          placeholder: "Выберите",
          size: "sm",
        },
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }