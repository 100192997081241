var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex align-items-center user-select-none" },
    [
      _c(
        "span",
        {
          staticClass: "mr-2 text",
          class: {
            _active: _vm.adapter.sortCol === _vm.keyCol,
            _selectable: _vm.keyColArrow,
            _white: _vm.enableWhite,
          },
          on: { click: _vm.labelClickEvent },
        },
        [_vm._v(" " + _vm._s(_vm.label) + " ")]
      ),
      _vm.hintContent
        ? _c(
            "span",
            {
              directives: [
                {
                  name: "c-popover",
                  rawName: "v-c-popover",
                  value: {
                    header: _vm.label,
                    content: _vm.hintContent,
                    placement: "up",
                    html: true,
                    appendToBody: true,
                  },
                  expression:
                    "{\n      header: label,\n      content: hintContent,\n      placement: 'up',\n      html: true,\n      appendToBody: true\n    }",
                },
              ],
              staticClass:
                "rounded-circle bg-white popover-wrap d-inline-flex justify-content-center mr-2",
            },
            [_vm._v(" ? ")]
          )
        : _vm._e(),
      _c("CIcon", {
        staticClass: "switcher",
        class: {
          _active: _vm.sorting(_vm.keyCol) || _vm.sorting(_vm.keyColArrow),
          _reverse:
            _vm.sorting(_vm.keyCol) === "asd" ||
            _vm.sorting(_vm.keyColArrow) === "asd",
          _white_fill: _vm.enableWhite,
        },
        attrs: { name: "arrow-strong" },
        nativeOn: {
          click: function ($event) {
            return _vm.arrowClickEvent.apply(null, arguments)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }