var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "switcher-wrap" }, [
    _c(
      "div",
      {
        staticClass: "switcher switcher_3",
        class: { _active: _vm.adapter.filterMA200Trend === 3 },
        on: {
          click: function ($event) {
            return _vm.clickEvent(3)
          },
        },
      },
      [_vm._v(" " + _vm._s(_vm.adapter.trendMA3) + " ")]
    ),
    _c(
      "div",
      {
        staticClass: "switcher switcher_2",
        class: { _active: _vm.adapter.filterMA200Trend === 2 },
        on: {
          click: function ($event) {
            return _vm.clickEvent(2)
          },
        },
      },
      [_vm._v(" " + _vm._s(_vm.adapter.trendMA2) + " ")]
    ),
    _c(
      "div",
      {
        staticClass: "switcher switcher_1",
        class: { _active: _vm.adapter.filterMA200Trend === 1 },
        on: {
          click: function ($event) {
            return _vm.clickEvent(1)
          },
        },
      },
      [_vm._v(" " + _vm._s(_vm.adapter.trendMA1) + " ")]
    ),
    _c(
      "span",
      {
        directives: [
          {
            name: "c-popover",
            rawName: "v-c-popover",
            value: {
              header: "MA",
              placement: "up",
              html: true,
              appendToBody: true,
              content: `
        🔴 ${_vm.$t("screener.tablesView.colorFilterHint.danger")}<br/>
        🟡 ${_vm.$t("screener.tablesView.colorFilterHint.warning")}<br/>
        🟢 ${_vm.$t("screener.tablesView.colorFilterHint.success")}
      `,
            },
            expression:
              "{\n      header: 'MA',\n      placement: 'up',\n      html: true,\n      appendToBody: true,\n      content: `\n        🔴 ${$t('screener.tablesView.colorFilterHint.danger')}<br/>\n        🟡 ${$t('screener.tablesView.colorFilterHint.warning')}<br/>\n        🟢 ${$t('screener.tablesView.colorFilterHint.success')}\n      `\n    }",
          },
        ],
        staticClass:
          "rounded-circle bg-dark popover-wrap d-inline-flex justify-content-center ml-2",
      },
      [_vm._v("?")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }