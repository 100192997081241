var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "d-flex align-items-center user-select-none",
      class: { active: _vm.keyCol === _vm.adapter.activeCol && _vm.showActive },
    },
    [
      _c(
        "span",
        { staticClass: "mr-2 text" },
        [
          _vm._t("default", function () {
            return [_vm._v(" " + _vm._s(_vm.label) + " ")]
          }),
        ],
        2
      ),
      _c("CIcon", {
        staticClass: "switcher",
        class: {
          _active: _vm.sorting,
          _reverse: _vm.sorting === "asd",
        },
        attrs: { name: "arrow-strong" },
        nativeOn: {
          click: function ($event) {
            $event.stopPropagation()
            return _vm.changeSort.apply(null, arguments)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }